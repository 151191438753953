import { FunctionComponent, h } from 'preact'
import { classNames } from '../../../services/css'
import { Svg } from '../svg/svg'
import styles from './icon.less'

export const Icon: FunctionComponent<{
	className?: string
	src: string
}> = ({ className, ...rest }) => (
	<Svg {...rest} className={classNames(styles.icon, className)} />
)
