import { Component, h } from 'preact'
import iconCheck from '../../../assets/images/icons/check.svg'
import { shallowEqual } from '../../../services/shallowEqual'
import { Icon } from '../icon/icon'
import styles from './list.less'

// require will make webpack include the whole svg folder in output
const getFlagImage = (code: string) => {
	try {
		return require(`../../../assets/images/flags/${code}.svg`)
	} catch (err) {
		// return base file name to register a 404
		return `img/${code}.svg`
	}
}

interface ListProps {
	items: Array<{
		id: string
		name: string
	}>
	select: (id: string) => void
	value?: string
}

interface ListState {
	selectedIndex: number
}

export class List extends Component<ListProps, ListState> {
	private listElement?: Element
	private selectedElement?: Element

	constructor(props: ListProps) {
		super(props)
		this.handleKeydown = this.handleKeydown.bind(this)
		this.state = { selectedIndex: -1 }
	}

	public shouldComponentUpdate(props: ListProps, state: ListState) {
		return !(shallowEqual(props, this.props) && shallowEqual(state, this.state))
	}

	public componentDidUpdate(prevProps: ListProps) {
		if (this.props.items.length !== prevProps.items.length) {
			this.setState({ selectedIndex: -1 }, this.scrollSelectedElementIntoView)
		}
	}

	public componentDidMount() {
		// Prevent the touchmove handler in mobile.ts from blocking scrolling
		this.listElement?.addEventListener(
			'touchmove',
			(e) => e.stopImmediatePropagation(),
			{ passive: false }
		)

		document.addEventListener('keydown', this.handleKeydown)
	}

	public componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeydown)
	}

	public handleKeydown(e: KeyboardEvent) {
		let { selectedIndex } = this.state
		if (e.key === 'Enter') {
			e.preventDefault()
			const item = this.props.items[selectedIndex]
			if (item) {
				this.props.select(item.id)
			}
		} else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
			e.preventDefault()
			selectedIndex += e.key === 'ArrowDown' ? 1 : -1
			if (selectedIndex >= 0 && selectedIndex < this.props.items.length) {
				this.setState({ selectedIndex }, this.scrollSelectedElementIntoView)
			}
		}
	}

	public scrollSelectedElementIntoView() {
		if (this.selectedElement) {
			this.selectedElement.scrollIntoView()
		}
	}

	public render() {
		return (
			<ol
				className={styles.list}
				ref={(elm) => (this.listElement = elm || undefined)}
			>
				{this.props.items.map((item, i) => (
					<li
						className={
							i === this.state.selectedIndex ? styles.selected : undefined
						}
						key={item.id}
						onClick={() => this.props.select(item.id)}
						ref={(elm) =>
							i === this.state.selectedIndex
								? (this.selectedElement = elm || undefined)
								: undefined
						}
					>
						<img alt="" className={styles.flag} src={getFlagImage(item.id)} />
						<span className={styles.code}>{item.id}</span>
						<span className={styles.name}>{item.name}</span>
						{this.props.value === item.id && (
							<Icon className={styles.check} src={iconCheck} />
						)}
					</li>
				))}
			</ol>
		)
	}
}
