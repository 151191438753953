export const cloneAsObject = (o: any) => {
	if (!(o instanceof Object)) {
		return o
	}

	const clone = {} as any

	for (const key in o) {
		clone[key] = cloneAsObject(o[key])
	}

	return clone
}
