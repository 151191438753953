import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import { setPermission } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Button } from '../common/button/button'
import { Popup } from '../common/popup/popup'
import styles from './permission.less'

export const Permission = () => {
	const dispatch = useAppDispatch()

	const showPermissionDialog = useAppSelector(
		(state) => state.showPermissionDialog
	)
	const isIOS = useAppSelector((state) => state.deviceInfo?.platform === 'ios')

	const handleClose = useCallback(
		() => dispatch(setPermission(false)),
		[dispatch]
	)

	return (
		<Popup onClose={handleClose} show={showPermissionDialog}>
			<div className={styles.permission}>
				<p>
					To detect the currency of the country you are in, we need access to
					the GPS in your device.
				</p>
				{!isIOS && (
					<Button
						aria-label="Disable GPS location"
						onClick={handleClose}
						secondary
					>
						Disable
					</Button>
				)}
				<Button
					aria-label="Request GPS location"
					onClick={() => dispatch(setPermission(true))}
				>
					Continue
				</Button>
			</div>
		</Popup>
	)
}
