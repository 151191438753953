import { h, render } from 'preact'
import { Provider } from 'react-redux'
import * as config from '../config'
import { configureStore } from './redux/store'
import { init as initApp } from './services/app'
import { init as initMobile } from './services/mobile'
import './styles/index.less'

initMobile()

if (process.env.NODE_ENV === 'production') {
	import('./services/analytics').then((analytics) =>
		analytics.init(config.googleAnalytics.id)
	)
	import('./services/offline').then((offline) => offline.init())
}

const { rehydrator, store } = configureStore()

rehydrator.then(initApp)

const init = () => {
	const { App } = require('./components/app')
	render(
		<Provider store={store}>
			{/*
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
  		// @ts-ignore */}
			<App />
		</Provider>,
		document.body
	)
}

if (module.hot) {
	module.hot.accept('./components/app', () => requestAnimationFrame(init))
}

init()
