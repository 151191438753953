/* eslint-disable react/jsx-key */
import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import { clearInput, setInput } from '../../redux/actions'
import { useAppDispatch } from '../../redux/hooks'
import { Key } from './key'
import styles from './keypad.less'

const keys = '123456789'.split('')

export const Keypad = () => {
	const dispatch = useAppDispatch()

	const clearInputValue = useCallback(() => dispatch(clearInput()), [dispatch])
	const setInputValue = useCallback(
		(s: string) => dispatch(setInput(s)),
		[dispatch]
	)

	return (
		<div className={styles.keypad}>
			{keys.map((v) => (
				<Key value={v} onClick={setInputValue} tabIndex={Number(v)} />
			))}
			<Key
				className={styles.functionKey}
				onClick={clearInputValue}
				tabIndex={12}
				value="C"
				label="Clear"
			/>
			<Key onClick={setInputValue} tabIndex={10} value="0" />
			<Key onClick={setInputValue} tabIndex={11} value="." label="Decimal" />
		</div>
	)
}
