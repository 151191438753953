/* eslint-disable react/no-unescaped-entities */
import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import { toggleShortcutDialog } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Popup } from '../common/popup/popup'
import styles from './shortcuts.less'

export const Shortcuts = () => {
	const dispatch = useAppDispatch()

	const showShortcuts = useAppSelector((state) => state.showShortcuts)

	const handleClose = useCallback(
		() => dispatch(toggleShortcutDialog()),
		[dispatch]
	)

	return (
		<Popup onClose={handleClose} show={showShortcuts}>
			<ul className={styles.shortcuts}>
				<li>
					<span className="bullet">
						<kbd>ESC</kbd>
					</span>
					Clear value
				</li>
				<li>
					<span className="bullet">
						<kbd>F</kbd>
					</span>
					Select "from" currency
				</li>
				<li>
					<span className="bullet">
						<kbd>T</kbd>
					</span>
					Select "to" currency
				</li>
				<li>
					<span className="bullet">
						<kbd>X</kbd>
					</span>
					Swap currencies
				</li>
			</ul>
		</Popup>
	)
}
