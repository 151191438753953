import { createReducer } from 'redux-promise-middleware-actions'
import config from '../../config'
import { LocationStatus, Position } from '../services/location'
import { RatesMap } from '../services/money'
import { cloneAsObject } from '../services/object'
import { CountryCurrencies } from '../services/resources'
import * as actions from './actions'

export interface Currency {
	id: string
	name: string
}

type DeviceInfo = ReturnType<typeof actions.getDeviceInfo>['payload']

export interface StoreState {
	countries?: any
	country?: { id: string; currency: string }
	countryCurrencies?: CountryCurrencies
	currencies: Currency[]
	currenciesHistory: string[]
	currency1: string
	currency2: string
	currentCurrencyPicker?: number
	deviceInfo?: DeviceInfo
	locationAllowed?: boolean
	locationStatus: LocationStatus
	position?: Position
	query: string
	rates?: RatesMap
	ratesTimestamp?: number
	showCurrencyPicker: boolean
	showDebug: boolean
	showDrawer: boolean
	showInstallDialog: boolean
	showPermissionDialog: boolean
	showShortcuts: boolean
	input: string
}

export const initialState: StoreState = {
	currencies: [],
	currenciesHistory: config.currencies.selected,
	currency1: config.currencies.default1,
	currency2: config.currencies.default2,
	locationStatus: LocationStatus.searching,
	query: '',
	showCurrencyPicker: false,
	showDebug: false,
	showDrawer: false,
	showInstallDialog: false,
	showPermissionDialog: false,
	showShortcuts: false,
	input: '100',
}

export default createReducer(initialState, (handleAction) => [
	handleAction(actions.rehydrate, (state, { payload }) =>
		state && payload ? payload : state
	),
	handleAction(actions.getDeviceInfo, (state, { payload }) => ({
		...state,
		deviceInfo: payload,
	})),
	handleAction(
		[
			actions.setInput,
			actions.clearInput,
			actions.setLocationStatus,
			actions.setDrawer,
			actions.togglePermissionDialog,
		],
		(state, { payload }) => ({
			...state,
			...payload,
		})
	),
	handleAction(actions.getCurrencies.fulfilled, (state, { payload }) => ({
		...state,
		currencies: Object.entries(payload).map(
			([id, name]) => ({ id, name } as Currency)
		),
	})),
	handleAction(
		actions.getCurrencyRates.fulfilled,
		(state, { payload }: any) => ({
			...state,
			rates: payload.rates,
			ratesTimestamp: payload.timestamp * 1000,
		})
	),
	handleAction(actions.getCountries.fulfilled, (state, { payload }) => ({
		...state,
		countries: payload,
	})),
	handleAction(
		actions.getCountryCurrencies.fulfilled,
		(state, { payload }) => ({
			...state,
			countryCurrencies: payload,
		})
	),
	handleAction(actions.setCurrency, (state, { payload }) => {
		const code = payload.currency1 || payload.currency2
		const history = new Set(
			[
				code,
				state.currency1,
				state.currency2,
				...state.currenciesHistory,
			].filter(Boolean)
		)
		const trimmed = Array.from(history).slice(
			0,
			config.currencies.historyLength
		)

		return {
			...state,
			...payload,
			currenciesHistory: trimmed,
		}
	}),
	handleAction(actions.toggleCurrencyPicker, (state, { payload }) => ({
		...state,
		currentCurrencyPicker: payload.number,
		showCurrencyPicker: !state.showCurrencyPicker,
	})),
	handleAction(actions.toggleDrawer, (state) => ({
		...state,
		showDrawer: !state.showDrawer,
	})),
	handleAction(actions.toggleShortcutDialog, (state) => ({
		...state,
		showShortcuts: !state.showShortcuts,
	})),
	handleAction(actions.swapCurrencies, (state) => ({
		...state,
		currency1: state.currency2,
		currency2: state.currency1,
	})),
	handleAction(actions.setPermission, (state, { payload }) => ({
		...state,
		locationAllowed: payload.locationAllowed,
		locationStatus: payload.locationAllowed
			? state.locationStatus
			: LocationStatus.disabled,
		showPermissionDialog: false,
	})),
	handleAction(actions.getLocation.pending, (state) => ({
		...state,
		locationStatus: LocationStatus.searching,
	})),
	handleAction(actions.getLocation.fulfilled, (state, { payload }) => ({
		...state,
		// overwrite timestamp due to Safari bug: http://openradar.appspot.com/9246279
		position: { ...cloneAsObject(payload), timestamp: Date.now() },
		locationStatus: LocationStatus.found,
	})),
	handleAction(actions.getLocation.rejected, (state) => ({
		...state,
		locationStatus: LocationStatus.failed,
	})),
	handleAction(actions.getCurrencyFromLocation, (state, { payload }: any) => {
		const newState: Partial<StoreState> = { country: payload }

		const { currency } = payload || ({} as any)
		if (currency && currency !== state.currency2) {
			newState.currenciesHistory = Array.from(
				new Set(state.currenciesHistory).add(currency)
			)
			newState.currency1 = currency
		}

		return { ...state, ...newState }
	}),
	handleAction(actions.search, (state, { payload }) => ({
		...state,
		query: payload.query,
	})),
	handleAction(actions.toggleDebug, (state) => ({
		...state,
		showDebug: !state.showDebug,
	})),
	handleAction(actions.toggleInstallDialog, (state) => ({
		...state,
		showInstallDialog: !state.showInstallDialog,
	})),
	handleAction(actions.install, (state) => ({
		...state,
		showInstallDialog: false,
	})),
])
