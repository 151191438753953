import { Store } from 'redux'
import * as actions from '../redux/actions'
import { StoreState } from '../redux/reducer'
import { parseNumber } from '../services/numbers'

export const init = ({ dispatch, getState }: Store<StoreState>) => {
	document.addEventListener('paste', (event) => {
		const eventData = event.clipboardData?.getData('Text')
		const pasted = parseNumber(eventData)

		if (eventData && !Number.isNaN(pasted)) {
			dispatch(actions.clearInput())
			dispatch(actions.setInput(String(pasted)))
		}
	})

	document.addEventListener('keydown', ({ key, target }) => {
		if ((target as any)?.tagName === 'INPUT') {
			return
		}

		switch (key.toLowerCase()) {
			case 'escape': {
				const { showCurrencyPicker, showPermissionDialog, showShortcuts } =
					getState()
				if (!(showCurrencyPicker || showPermissionDialog || showShortcuts)) {
					dispatch(actions.clearInput())
				}
				break
			}
			case '?': {
				return dispatch(actions.toggleShortcutDialog())
			}
			case '\\': {
				return dispatch(actions.toggleDrawer())
			}
			case 'i': {
				return dispatch(actions.toggleInstallDialog())
			}
			case 'p': {
				return dispatch(actions.togglePermissionDialog(true))
			}
			case 'f':
			case 't': {
				const { showCurrencyPicker } = getState()
				if (!showCurrencyPicker) {
					dispatch(
						actions.toggleCurrencyPicker(key.toLowerCase() === 'f' ? 1 : 2)
					)
				}
				break
			}
			case 'x': {
				return dispatch(actions.swapCurrencies())
			}
			case ',':
			case '.': {
				return dispatch(actions.setInput('.'))
			}
			case '0':
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
			case '6':
			case '7':
			case '8':
			case '9': {
				return dispatch(actions.setInput(key))
			}
		}
	})
}
