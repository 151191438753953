import { FunctionComponent, h } from 'preact'
import { classNames } from '../../../services/css'
import styles from './button.less'

export const Button: FunctionComponent<{
	className?: string
	secondary?: boolean
	[key: string]: any
}> = ({ className, children, secondary, ...rest }) => (
	<button
		className={classNames(
			styles.button,
			{ [styles.secondary]: !!secondary },
			className
		)}
		type="button"
		{...rest}
	>
		{children}
	</button>
)
