import { FunctionComponent, h } from 'preact'
import { useEffect } from 'preact/hooks'
import { CSSTransition as CSSTransitionOrg } from 'react-transition-group'
import iconClear from '../../../assets/images/icons/clear.svg'
import { IconButton } from '../icon-button/icon-button'
import styles from './popup.less'

const CSSTransition = CSSTransitionOrg as any

export const Popup: FunctionComponent<{
	/** Handle closing of the popup. This function should be memoized with useCallback to avoid unnecessary rerenders. */
	onClose: () => void
	show?: boolean
}> = ({ children, onClose, show }) => {
	useEffect(() => {
		const handleKeydown = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				e.stopImmediatePropagation()
				onClose()
			}
		}

		if (show) {
			document.addEventListener('keydown', handleKeydown)
		}

		return () => document.removeEventListener('keydown', handleKeydown)
	}, [onClose, show])

	return (
		<CSSTransition
			in={show}
			classNames="fadeTransition"
			timeout={500}
			mountOnEnter
			unmountOnExit
		>
			{/*
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
  		// @ts-ignore */}
			<div className={styles.popupContainer}>
				<IconButton
					aria-label="Close"
					className={styles.btnClose}
					onClick={onClose}
					secondary
					src={iconClear}
				/>
				<div className={styles.popupInner}>{children}</div>
			</div>
		</CSSTransition>
	)
}
