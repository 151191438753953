import { h } from 'preact'
import { useCallback, useEffect } from 'preact/hooks'
import { search, setCurrency, toggleCurrencyPicker } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
	getCommonCurrencies,
	getCurrenciesFiltered,
} from '../../redux/selectors'
import { List } from '../common/list/list'
import { Popup } from '../common/popup/popup'
import styles from './currencies.less'

export const Currencies = () => {
	const dispatch = useAppDispatch()

	const currenciesCommon = useAppSelector(getCommonCurrencies)
	const currenciesFiltered = useAppSelector(getCurrenciesFiltered)
	const query = useAppSelector((state) => state.query)
	const currency1 = useAppSelector((state) => state.currency1)
	const currency2 = useAppSelector((state) => state.currency2)
	const currentCurrencyPicker = useAppSelector(
		(state) => state.currentCurrencyPicker
	)
	const showCurrencyPicker = useAppSelector((state) => state.showCurrencyPicker)

	const selectedCurrency = currentCurrencyPicker === 1 ? currency1 : currency2

	const handleSearch = useCallback(
		({ target }: any) => {
			if (query !== target.value) {
				dispatch(search(target.value))
			}
		},
		[dispatch, query]
	)

	const handleSubmit = (event: h.JSX.TargetedEvent<HTMLFormElement, Event>) =>
		event.preventDefault()

	const handleClose = useCallback(
		() => dispatch(toggleCurrencyPicker()),
		[dispatch]
	)

	const pickCurrency = useCallback(
		(code: string) => {
			dispatch(search())
			dispatch(setCurrency(code, currentCurrencyPicker!))
			dispatch(toggleCurrencyPicker(undefined))
		},
		[currentCurrencyPicker, dispatch]
	)

	useEffect(() => {
		if (!showCurrencyPicker) return
		requestAnimationFrame(() => {
			document
				.querySelector<HTMLInputElement>('[data-testid=queryInput]')
				?.focus()
		})
	}, [showCurrencyPicker])

	return (
		<Popup onClose={handleClose} show={showCurrencyPicker}>
			<div className={styles.currencies}>
				<form action="." onSubmit={handleSubmit}>
					<input
						// eslint-disable-next-line react/no-unknown-property
						autofocus
						className={styles.queryInput}
						data-testid="queryInput"
						onKeyUp={handleSearch}
						// eslint-disable-next-line react/no-unknown-property
						onSearch={handleSearch}
						placeholder="Search for currency"
						type="search"
						value={query}
					/>
				</form>
				{query ? (
					<div className={styles.list}>
						<List
							items={currenciesFiltered}
							select={pickCurrency}
							value={selectedCurrency}
						/>
						{!currenciesFiltered.length && (
							<p className={styles.noResults}>No results</p>
						)}
					</div>
				) : (
					<div className={styles.list}>
						<List
							items={currenciesCommon}
							select={pickCurrency}
							value={selectedCurrency}
						/>
						<p className={styles.hint}>Search to select from more currencies</p>
					</div>
				)}
			</div>
		</Popup>
	)
}
