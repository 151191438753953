import { Fragment, h } from 'preact'
import { useCallback } from 'preact/hooks'
import { SnapJSState } from 'snapjs'
import { setDrawer } from '../redux/actions'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { Page } from './common/page/page'
import { Snap } from './common/snap/snap'
import { Currencies } from './currencies/currencies'
import { Debug } from './debug/debug'
import { Header } from './header/header'
import { Install } from './install/install'
import { Keypad } from './keypad/keypad'
import { Permission } from './permission/permission'
import { Settings } from './settings/settings'
import { Shortcuts } from './shortcuts/shortcuts'
import { Values } from './values/values'

export const App = () => {
	const dispatch = useAppDispatch()

	const showDebug = useAppSelector((state) => state.showDebug)
	const showDrawer = useAppSelector((state) => state.showDrawer)
	const handleDrawerChange = useCallback(
		(state: SnapJSState) => dispatch(setDrawer(state)),
		[dispatch]
	)

	return (
		<>
			<Snap
				onChange={handleDrawerChange}
				show={showDrawer}
				right={<Settings />}
			>
				<Page>
					<Header />
					<div className="content">
						<Values />
						<Keypad />
					</div>
				</Page>
			</Snap>
			<Currencies />
			<Permission />
			<Shortcuts />
			<Install />
			{showDebug && <Debug />}
		</>
	)
}
