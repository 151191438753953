import type { Middleware } from 'redux'
import { sendEvent, trackError } from '../services/analytics'
import * as actions from './actions'

export const errorHandler: Middleware = () => (next) => async (action) => {
	try {
		return await next(action)
	} catch (err) {
		trackError(err as Error)
		throw err
	}
}

export const analytics: Middleware = () => (next) => (action) => {
	switch (action.type) {
		case String(actions.clearInput):
			sendEvent('Keypad', 'Clear')
			break

		case String(actions.copyToClipboard):
			sendEvent('Clipboard', 'Copy')
			break

		case String(actions.setCurrency):
			sendEvent(
				'Currency',
				'Select',
				action.payload.currency1 || action.payload.currency2
			)
			break

		case String(actions.swapCurrencies):
			sendEvent('Currency', 'Swap')
			break

		case String(actions.setPermission):
			sendEvent('Permission', 'Allow', String(action.payload.locationAllowed))
			break

		case String(actions.install):
			sendEvent('Install', 'Accepted')
			break

		case String(actions.toggleShortcutDialog):
			sendEvent('Shortcuts', 'Toggle')
			break
	}

	return next(action)
}
