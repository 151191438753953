import { debounce } from 'lodash-es'
import { cloneElement, Component, h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import SnapJS, { SnapJSState } from 'snapjs'

export const Snap = ({
	onChange,
	right,
	show,
	children,
}: {
	children: any
	onChange: (state: SnapJSState) => void
	right: JSX.Element
	show: boolean
}) => {
	const snapper = useRef<SnapJS>()
	const component = useRef<Component>()

	useEffect(() => {
		if (component.current) {
			snapper.current = new SnapJS({
				element: component.current.base,
				disable: 'left',
			})
			snapper.current.on(
				'animated',
				debounce(() => onChange(snapper.current!.state().state), 500)
			)
		}
	}, [])

	useEffect(() => {
		if (show) {
			snapper.current?.open('right')
		} else {
			snapper.current?.close()
		}
	}, [show])

	return (
		<div>
			<div className="snap-drawers">
				<div className="snap-drawer snap-drawer-right">{right}</div>
			</div>
			{cloneElement(children as any, {
				className: 'snap-content',
				ref: (c: Component<any, any>) => (component.current = c),
			})}
		</div>
	)
}
