export interface RatesMap {
	[country: string]: number
}

let base = ''
let rates: RatesMap = {}

/**
 * Configure conversion rates to use.
 * @param options Options object containing conversion rates.
 */
export const configure = (options: { base: string; rates: RatesMap }) => {
	base = options.base
	rates = options.rates
}

const getRate = (from: string, to: string) => {
	// Make sure the base rate is in the rates object:
	rates[base] = 1

	// Return 0 if either rate isn't in the rates array
	if (!rates[to] || !rates[from]) {
		return 0
	}

	// If `from` currency === base, return the basic exchange rate for the `to` currency
	if (from === base) {
		return rates[to]
	}

	// If `to` currency === base, return the basic inverse rate of the `from` currency
	if (to === base) {
		return 1 / rates[from]
	}

	// Otherwise, return the `to` rate multipled by the inverse of the `from` rate to get the
	// relative exchange rate between the two currencies
	return rates[to] * (1 / rates[from])
}

/**
 * Converts an value from one currency to another.
 * @param value Value to convert.
 * @param from Currency of the input.
 * @param to Currency to convert to.
 */
export const convert = (value: number | string, from: string, to: string) => {
	return Number(value) * getRate(from, to)
}
