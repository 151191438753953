import { throttle } from 'lodash-es'
import { applyMiddleware, createStore } from 'redux'
import promiseMiddleware from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import { loadState, saveState } from '../services/storage'
import { rehydrate } from './actions'
import { analytics, errorHandler } from './middleware'
import rootReducer, { StoreState } from './reducer'

const middleware = [analytics, errorHandler, thunkMiddleware, promiseMiddleware]

if (process.env.NODE_ENV === 'development') {
	const { logger } = require('redux-logger')
	middleware.push(logger)
}

export function configureStore() {
	const store = createStore(rootReducer, applyMiddleware(...middleware))

	const rehydrator = loadState<StoreState>().then((storedState) => {
		if (storedState) {
			store.dispatch(rehydrate(storedState))
		}

		store.subscribe(throttle(() => saveState(store.getState()), 1000))

		return store
	})

	return { rehydrator, store }
}

export type Store = ReturnType<typeof configureStore>['store']
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = any
