/** [lng, lat] */
type ICoordinates = [number, number]

interface DataPolygon {
	type: 'Polygon'
	coordinates: ICoordinates[][]
}

interface DataMultiPolygon {
	type: 'MultiPolygon'
	coordinates: ICoordinates[][]
}

interface CountryData {
	type: 'Feature'
	id: string
	properties: { name: string }
	geometry: DataPolygon | DataMultiPolygon
}

export interface CountryDataCollection {
	type: 'FeatureCollection'
	features: CountryData[]
}

/**
 * Calculate whether a coordinate point is inside the polygon created by an array of coordinates.
 * @param coordinates Longitude and latitude (rotated ISO 6709)
 * @param polygon Array of coordinates constituting a polygon (rotated ISO 6709)
 * @link https://en.wikipedia.org/wiki/ISO_6709#Items
 * @link https://stackoverflow.com/questions/217578/how-can-i-determine-whether-a-2d-point-is-within-a-polygon
 */
const isPointInPolygon = ([x, y]: ICoordinates, polygon: ICoordinates[]) => {
	const sides = polygon.length - 1
	let inside = false
	for (let i = 0, j = sides - 1; i < sides; j = i++) {
		if (
			(polygon[i][0] < y && polygon[j][0] >= y) ||
			(polygon[j][0] < y && polygon[i][0] >= y)
		) {
			if (
				polygon[i][1] +
					((y - polygon[i][0]) / (polygon[j][0] - polygon[i][0])) *
						(polygon[j][1] - polygon[i][1]) <
				x
			) {
				inside = !inside
			}
		}
	}
	return inside
}

const isInCountry = ({ geometry }: CountryData, point: ICoordinates) =>
	geometry.coordinates.some((c) => {
		if (geometry.type === 'MultiPolygon') {
			return c.some((cc) => isPointInPolygon(point, cc as any))
		}
		return isPointInPolygon(point, c)
	})

export const findCountryByCoordinates = (
	data: CountryDataCollection,
	lat: number,
	lng: number
) => {
	const match = data.features.find((c) => isInCountry(c, [lat, lng]))
	if (match) {
		return {
			id: match.id,
			name: match.properties.name,
		}
	}
}
