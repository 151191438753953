import config from '../../config'
import {
	getCountries,
	getCountryCurrencies,
	getCurrencies,
	getCurrencyRates,
	getDeviceInfo,
	initLocationUpdatesAsync,
} from '../redux/actions'
import type { Store } from '../redux/store'
import { init as initShortcuts } from './shortcuts'

export const init = (store: Store) => {
	initShortcuts(store)

	store.dispatch(getDeviceInfo())
	store.dispatch(initLocationUpdatesAsync())
	store.dispatch(getCountries())
	store.dispatch(getCountryCurrencies())
	store.dispatch(getCurrencies())

	const startRatesUpdates = () => {
		const { ratesTimestamp } = store.getState()

		if (!ratesTimestamp || Date.now() - ratesTimestamp > config.dataExpire) {
			store.dispatch(getCurrencyRates())
		}

		const ticker = setTimeout(startRatesUpdates, config.dataExpire)

		return () => clearTimeout(ticker)
	}

	return startRatesUpdates()
}
