import { SplashScreen } from '@capacitor/splash-screen'

const noop = () => undefined

// Hide the splash (normally shows for 3s)
SplashScreen.hide().catch(noop)

// Try to disable overscroll/"drag page" on iOS
window.addEventListener('touchmove', (e) => e.preventDefault(), {
	passive: false,
})

export const init = () => {
	// Init fastclick for Safari Mobile on Homescreen or in WebView
	const isHomescreen = !!(navigator as any).standalone
	const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
		navigator.userAgent
	)

	if (isHomescreen || isWebView) {
		document.addEventListener(
			'DOMContentLoaded',
			() => import('./safari'),
			false
		)
	}
}
