import { FunctionComponent, h } from 'preact'
import { classNames } from '../../services/css'
import { Button } from '../common/button/button'
import styles from './keypad.less'

export const Key: FunctionComponent<{
	className?: string
	onClick: (s: string) => void
	label?: string
	tabIndex: number
	value: string
}> = (props) => {
	return (
		<Button
			aria-label={props.label || props.value}
			className={classNames(styles.key, props.className)}
			data-snap-ignore="true"
			onClick={() => props.onClick(props.value)}
			tabIndex={props.tabIndex}
		>
			{props.value}
		</Button>
	)
}
