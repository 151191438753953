import { h } from 'preact'
import iconArrowDown from '../../assets/images/icons/arrow-down.svg'
import iconArrowSync from '../../assets/images/icons/arrow-sync.svg'
import {
	copyToClipboard,
	swapCurrencies,
	toggleCurrencyPicker,
} from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
	getConvertedAmount,
	getCurrencyFrom,
	getCurrencyTo,
	getInput,
} from '../../redux/selectors'
import { classNames } from '../../services/css'
import { formatCurrency } from '../../services/numbers'
import { Button } from '../common/button/button'
import { IconButton } from '../common/icon-button/icon-button'
import { Icon } from '../common/icon/icon'
import styles from './values.less'

export const Values = () => {
	const dispatch = useAppDispatch()

	const currency1 = useAppSelector(getCurrencyFrom)
	const currency2 = useAppSelector(getCurrencyTo)
	const from = useAppSelector(getInput)
	const to = useAppSelector(getConvertedAmount)
	const formattedFrom = formatCurrency(Number(from))
	const formattedTo = formatCurrency(Number(to))

	const valuesLength = formattedFrom.length + formattedTo.length
	const classValue = classNames(styles.amount, {
		[styles.smaller]: valuesLength > 20,
	})

	const handleCurrencyClick = (n: number, event: MouseEvent) => {
		event.preventDefault()
		dispatch(toggleCurrencyPicker(n))
	}

	const handleCurrencySwap = (event: MouseEvent) => {
		event.preventDefault()
		dispatch(swapCurrencies())
	}

	const handleCopyClick = () => {
		navigator.clipboard.writeText(to)
		dispatch(copyToClipboard())
	}

	return (
		<div className={styles.values}>
			<div className={classNames(styles.value, styles.value1)}>
				<span className={classValue} onClick={handleCopyClick}>
					{formattedFrom}
				</span>
				<Button
					aria-label="Currency from"
					className={styles.btnCurrency}
					onClick={handleCurrencyClick.bind(null, 1)}
					tabIndex={13}
				>
					{currency1}
					<Icon className={styles.btnIcon} src={iconArrowDown} />
				</Button>
			</div>
			<div className={styles.value}>
				<span className={classValue} onClick={handleCopyClick}>
					{formattedTo}
				</span>
				<Button
					aria-label="Currency to"
					className={styles.btnCurrency}
					onClick={handleCurrencyClick.bind(null, 2)}
					tabIndex={14}
				>
					{currency2}
					<Icon className={styles.btnIcon} src={iconArrowDown} />
				</Button>
				<IconButton
					aria-label="Swap currencies"
					className={styles.btnSwap}
					onClick={handleCurrencySwap}
					src={iconArrowSync}
					tabIndex={15}
				/>
			</div>
		</div>
	)
}
