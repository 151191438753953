import { formatDistance } from 'date-fns/formatDistance'
import { formatISO } from 'date-fns/formatISO'
import { h } from 'preact'
import iconCheck from '../../assets/images/icons/check.svg'
import {
	getCurrencyRates,
	getPositionAsync,
	setPermission,
	toggleDebug,
} from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { LocationStatus } from '../../services/location'
import { Button } from '../common/button/button'
import { Icon } from '../common/icon/icon'
import styles from './settings.less'

export const Settings = () => {
	const dispatch = useAppDispatch()

	const locationAllowed = useAppSelector((state) => state.locationAllowed)
	const locationStatus = useAppSelector((state) => state.locationStatus)
	const position = useAppSelector((state) => state.position)
	const ratesTimestamp = useAppSelector((state) => state.ratesTimestamp)

	let location = ''
	if (locationStatus === LocationStatus.failed) {
		location = 'Location currently unavailable'
	} else if (position) {
		location = `Location updated ${formatDistance(
			position.timestamp,
			Date.now()
		)} ago`
	}

	let rates = ''
	if (ratesTimestamp) {
		rates = `Rates updated ${formatDistance(ratesTimestamp, Date.now())} ago`
	}

	return (
		<div className={styles.settings}>
			<section className={styles.currencies}>
				<h2 className={styles.header}>Location</h2>
				<ol>
					<li onClick={() => dispatch(setPermission(true))}>
						<span className={styles.check}>
							{locationAllowed && (
								<Icon className={styles.check} src={iconCheck} />
							)}
						</span>
						<span className={styles.name}>Auto update (1 hr)</span>
					</li>
					<li onClick={() => dispatch(setPermission(false))}>
						<span className={styles.check}>
							{!locationAllowed && (
								<Icon className={styles.check} src={iconCheck} />
							)}
						</span>
						<span className={styles.name}>Don't update</span>
					</li>
				</ol>
			</section>
			<section>
				<Button
					className={styles.settingsBtn}
					onClick={() => dispatch(getCurrencyRates())}
				>
					Refresh rates
				</Button>
				<Button
					className={styles.settingsBtn}
					onClick={() => dispatch(getPositionAsync())}
				>
					Refresh location
				</Button>
			</section>
			<footer>
				<div>{rates}</div>
				<div>{location}</div>
				<div className={styles.version} onClick={() => dispatch(toggleDebug())}>
					Version: {formatISO(__BUILDTS__, { representation: 'date' })}
				</div>
			</footer>
		</div>
	)
}
