import { createSelector } from 'reselect'
import { convert } from '../services/money'
import { search } from '../services/search'
import { StoreState } from './reducer'

export const getCurrencies = (state: StoreState) => state.currencies
export const getCurrenciesHistory = (state: StoreState) =>
	state.currenciesHistory.filter(Boolean)
export const getInput = (state: StoreState) => state.input
export const getCurrencyFrom = (state: StoreState) => state.currency1
export const getCurrencyTo = (state: StoreState) => state.currency2
export const getQuery = (state: StoreState) => state.query
export const getRatesTimestamp = (state: StoreState) => state.ratesTimestamp

export const getConvertedAmount = createSelector(
	getInput,
	getCurrencyFrom,
	getCurrencyTo,
	getRatesTimestamp, // only added to trigger updates
	(input, from, to) => convert(input, from, to).toFixed(2)
)

export const getCommonCurrencies = createSelector(
	getCurrencies,
	getCurrenciesHistory,
	(currencies, history) =>
		history.map((code) => currencies.find(({ id }) => id === code)!)
)

export const getCurrenciesFiltered = createSelector(
	getCurrencies,
	getQuery,
	(currencies, query) =>
		search(currencies, query, ({ id, name }) => `${id} ${name}`)
)
