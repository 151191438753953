import { Capacitor } from '@capacitor/core'
import {
	createAction,
	createAsyncAction,
} from 'redux-promise-middleware-actions'
import * as config from '../../config'
import * as input from '../services/input'
import * as location from '../services/location'
import { configure as configureRates } from '../services/money'
import { tap } from '../services/promise'
import * as resources from '../services/resources'
import { StoreState } from './reducer'

let ticker: number

export const rehydrate = createAction('REHYDRATE', (state: StoreState) => {
	// side effect: configure conversion rates
	if (state.rates) {
		configureRates({
			base: config.currencies.default1,
			rates: state.rates,
		})
	}
	return state
})

export const getDeviceInfo = createAction('DEVICE_INFO', () => ({
	platform: Capacitor.getPlatform(),
	isNativePlatform: Capacitor.isNativePlatform(),
}))

export const getCurrencies = createAsyncAction(
	'CURRENCIES',
	resources.getCurrencies
)

export const getCurrencyRates = createAsyncAction('RATES', () =>
	tap(resources.getCurrencyRates(), configureRates)
)

export const getCountries = createAsyncAction(
	'COUNTRIES',
	resources.getCountries
)

export const getCountryCurrencies = createAsyncAction(
	'COUNTRY_CURRENCIES',
	resources.getCountryCurrencies
)

export const setInput = createAction('INPUT', (s: string) => ({
	input: input.setValue(s),
}))

export const clearInput = createAction('INPUT_CLEAR', () => ({
	input: input.clearValue(),
}))

export const copyToClipboard = createAction('CLIPBOARD_COPY')

export const setCurrency = createAction(
	'CURRENCY',
	(code: string, n: number) => ({ [`currency${n}`]: code })
)

export const swapCurrencies = createAction('SWAP_CURRENCIES', () => ({}))

export const setDrawer = createAction(
	'DRAWER',
	(state: 'left' | 'right' | 'closed') => ({
		showDrawer: state === 'right',
	})
)

export const toggleDebug = createAction('TOGGLE_DEBUG')

export const toggleCurrencyPicker = createAction(
	'TOGGLE_CURRENCY_PICKER',
	(n?: number) => ({ number: n })
)

export const toggleDrawer = createAction('TOGGLE_DRAWER')

export const toggleShortcutDialog = createAction('TOGGLE_SHORTCUTS', () => ({}))

export const togglePermissionDialog = createAction(
	'TOGGLE_PERMISSION_DIALOG',
	(showPermissionDialog: boolean) => ({ showPermissionDialog })
)

export const toggleInstallDialog = createAction('TOGGLE_INSTALL', () => ({}))

export const install = createAction('INSTALL')

export const search = createAction('SEARCH', (query = '') => ({ query }))

export const getCurrencyFromLocation = createAction(
	'LOCATION_CURRENCY',
	location.getCurrencyFromLocation
)

export const getLocation = createAsyncAction('POSITION', location.getLocation)

export const setLocationStatus = createAction(
	'LOCATION_STATUS',
	(locationStatus: location.LocationStatus) => ({ locationStatus })
)

/** Gets the location and then looks up the currency. */
export const getPositionAsync = () => (dispatch: any, getState: any) =>
	tap(dispatch(getLocation()), (res: { value: location.Position }) => {
		const { countries, countryCurrencies } = getState()
		dispatch(getCurrencyFromLocation(countries, countryCurrencies, res.value))
	})

export const startLocationUpdatesAsync = () => async (dispatch: any) => {
	if (!location.isSupported()) {
		return dispatch(setLocationStatus(location.LocationStatus.unsupported))
	}

	dispatch(getPositionAsync())

	clearTimeout(ticker)
	ticker = setTimeout(
		() => dispatch(startLocationUpdatesAsync()),
		config.location.updateInterval
	) as any
}

export const initLocationUpdatesAsync =
	() => (dispatch: any, getState: () => StoreState) => {
		const { locationAllowed } = getState()

		if (locationAllowed === true) {
			dispatch(startLocationUpdatesAsync())
		} else if (locationAllowed === false) {
			dispatch(setLocationStatus(location.LocationStatus.disabled))
		} else {
			dispatch(togglePermissionDialog(true))
		}
	}

export const getLocationAsync =
	() => (dispatch: any, getState: () => StoreState) => {
		const { locationAllowed } = getState()

		if (locationAllowed) {
			dispatch(startLocationUpdatesAsync())
		} else {
			dispatch(togglePermissionDialog(true))
		}
	}

export const setPermission = createAction(
	'PERMISSION',
	(locationAllowed: boolean) => ({ locationAllowed })
)

export const setPermissionAsync =
	(locationAllowed: boolean) => (dispatch: any) => {
		dispatch(setPermission(locationAllowed))

		if (locationAllowed) {
			dispatch(startLocationUpdatesAsync())
		}
	}
