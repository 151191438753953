export const clearDelay = 5000

let currentValue = '0'
let timestamp = Date.now()

const hasTimedOut = () => Date.now() - timestamp > clearDelay
const isDecimal = (value: string) => value.indexOf('.') > -1

export const getValue = () => currentValue

export const setValue = (input: string, ts: number = Date.now()) => {
	if (hasTimedOut()) {
		currentValue = input === '.' ? '0.' : input
	} else if (input === '.' && isDecimal(currentValue)) {
		// Do nothing / fall through if entering decimal twice
	} else {
		if (currentValue === '0' && input !== '.') {
			currentValue = input
		} else {
			currentValue = currentValue + input
		}
	}

	timestamp = ts

	return currentValue
}

export const clearValue = () => (currentValue = '0')
