import { h } from 'preact'
import iconMenu from '../../assets/images/icons/menu.svg'
import { getLocation, toggleDrawer } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { IconButton } from '../common/icon-button/icon-button'
import { Status } from '../location/status'
import styles from './header.less'

export const Header = () => {
	const dispatch = useAppDispatch()

	const locationStatus = useAppSelector((state) => state.locationStatus)

	return (
		<header className={styles.header}>
			<IconButton
				aria-label="Toggle GPS location"
				className={styles.btnLocation}
				onClick={() => dispatch(getLocation())}
				tabIndex={17}
			>
				<Status status={locationStatus} />
			</IconButton>
			<h1 className={styles.title}>Kuranz</h1>
			<IconButton
				aria-label="Settings"
				className={styles.btnSettings}
				onClick={() => dispatch(toggleDrawer())}
				src={iconMenu}
				tabIndex={16}
			/>
		</header>
	)
}
