import { FunctionComponent, h } from 'preact'
import { classNames } from '../../../services/css'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import styles from './icon-button.less'

export const IconButton: FunctionComponent<{
	className?: string
	src?: string
	[key: string]: any
}> = ({ className, children, src, ...rest }) => (
	<Button className={classNames(styles.iconButton, className)} {...rest}>
		{src ? <Icon src={src} /> : children}
	</Button>
)
