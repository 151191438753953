import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import { toggleDebug } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Popup } from '../common/popup/popup'
import styles from './debug.less'

export const Debug = () => {
	const dispatch = useAppDispatch()

	const state = useAppSelector((s) => s)

	const handleClose = useCallback(() => dispatch(toggleDebug()), [dispatch])

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { countries, countryCurrencies, currencies, rates, ...rest } = state

	return (
		<Popup onClose={handleClose} show>
			<div className={styles.debug}>
				<pre>{JSON.stringify(rest, null, '  ')}</pre>
			</div>
		</Popup>
	)
}
