const match = (input = '', query = ''): boolean => {
	let lastMatch = -1
	return query.split('').every((c) => {
		lastMatch = input.indexOf(c, lastMatch + 1)
		if (lastMatch > -1) {
			return true
		}
		return false
	})
}

export const search = <T>(
	input: T[],
	query: string,
	selector: (arg: T) => string = (x) => x as any
) => {
	const q = query.trim().toLowerCase()
	if (q) {
		return input.filter((s) => match(selector(s).toLowerCase(), q))
	} else {
		return input
	}
}
