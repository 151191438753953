import { h } from 'preact'
import { LocationStatus } from '../../services/location'
import iconLocationDisabled from '../../assets/images/icons/location-disabled.svg'
import iconLocationSearching from '../../assets/images/icons/location-searching.svg'
import iconMyLocation from '../../assets/images/icons/my-location.svg'
import { Icon } from '../common/icon/icon'

export const Status = ({ status }: { status: LocationStatus }) => {
	switch (status) {
		case LocationStatus.disabled:
		case LocationStatus.failed:
		case LocationStatus.unsupported:
			return <Icon src={iconLocationDisabled} />
		case LocationStatus.found:
			return <Icon src={iconMyLocation} />
		case LocationStatus.searching:
			return <Icon src={iconLocationSearching} />
	}
}
