/**
 * Concats all class names into a string.
 * Takes an object where key is the class name and the boolean value determines if it should be applied.
 */
export const classNames = (
	...args: Array<string | { [key: string]: boolean } | undefined | null>
): string => {
	const out: string[] = []

	for (const c of args) {
		if (!c) {
			continue
		} else if (typeof c === 'string') {
			out.push(c.trim())
		} else {
			Object.keys(c).reduce((prev, key) => {
				if (c[key]) {
					prev.push(key)
				}
				return prev
			}, out)
		}
	}

	return out.join(' ')
}
