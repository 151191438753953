import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import { install, toggleInstallDialog } from '../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Button } from '../common/button/button'
import { Popup } from '../common/popup/popup'
import styles from './install.less'

interface InstallEvent extends Event {
	prompt: () => void
	userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

export const Install = () => {
	const dispatch = useAppDispatch()

	const toggleDialog = useCallback(
		() => dispatch(toggleInstallDialog()),
		[dispatch]
	)

	const showInstallDialog = useAppSelector((state) => state.showInstallDialog)

	const [installEvent, setInstallEvent] = useState<InstallEvent | undefined>(
		undefined
	)

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault()
			setInstallEvent(e as InstallEvent)
			toggleDialog()
		})
	}, [toggleDialog])

	const handleInstall = async () => {
		if (!installEvent) {
			return
		}

		installEvent.prompt()

		const choice = await installEvent.userChoice
		if (choice.outcome === 'accepted') {
			dispatch(install())
		}

		toggleDialog()
		setInstallEvent(undefined)
	}

	return (
		<Popup onClose={toggleDialog} show={showInstallDialog}>
			<div className={styles.install}>
				<p>Add Kuranz to the homescreen</p>
				<Button aria-label="Cancel" onClick={toggleDialog} secondary>
					Cancel
				</Button>
				<Button aria-label="Add To Homescreen" onClick={handleInstall}>
					Add
				</Button>
			</div>
		</Popup>
	)
}
