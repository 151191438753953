const makeRegExp = (s: string) => new RegExp(`\\${s}`, 'g')

export const decimalSeparatorPattern = (() =>
	makeRegExp((1.1).toLocaleString().substr(1, 1)))()

export const thousandsSeparatorPattern = (() =>
	makeRegExp((1000).toLocaleString().substr(1, 1)))()

export const formatCurrency = (n = 0) =>
	n.toLocaleString(navigator.language, {
		maximumFractionDigits: 2,
	})

export const parseNumber = (input = '') =>
	Number(
		(input || '0')
			// remove thousands separator(s)
			.replace(thousandsSeparatorPattern, '')
			// use English decimal separator
			.replace(decimalSeparatorPattern, '.')
	)
