import { Geolocation } from '@capacitor/geolocation'
import { findCountryByCoordinates, CountryDataCollection } from './geotools'
import { CountryCurrencies } from './resources'

export interface Position {
	coords: {
		latitude: number
		longitude: number
	}
	timestamp: number
}

export enum LocationStatus {
	disabled,
	failed,
	found,
	searching,
	unsupported,
}

export const isSupported = () =>
	location.href.includes('capacitor') || !!navigator.geolocation

export const getLocation = () => Geolocation.getCurrentPosition()

export const getCurrencyFromLocation = (
	countries: CountryDataCollection,
	countryCurrencies: CountryCurrencies,
	{ coords }: Position
) => {
	const country = findCountryByCoordinates(
		countries,
		coords.latitude,
		coords.longitude
	)
	if (country) {
		return {
			id: country.id,
			currency: countryCurrencies[country.id],
		}
	}
}
