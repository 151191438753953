const API_KEY = 'ceb3c164506e408da89b20f514d2b43a'

module.exports = {
	currencies: {
		default1: 'USD',
		default2: 'EUR',
		selected: ['USD', 'EUR', 'CAD', 'GBP', 'CHF', 'HKD', 'JPY', 'DKK'],
		common: [
			'USD',
			'EUR',
			'JPY',
			'GBP',
			'CHF',
			'CAD',
			'AUD',
			'MXN',
			'CNY',
			'NZD',
			'SEK',
			'RUB',
			'HKD',
			'NOK',
			'SGD',
			'TRY',
			'KRW',
			'ZAR',
			'BRL',
			'INR',
			'DKK',
		],
		historyLength: 10,
	},
	data: {
		currencies: `https://openexchangerates.org/api/currencies.json?app_id=${API_KEY}`,
		rates: 'https://kuranz.com/.netlify/functions/rates',
	},
	dataExpire: 3600000, // 60 min
	googleAnalytics: {
		id: 'G-4GJ6Y4N623',
	},
	location: {
		updateInterval: 1800000, // 30 min
	},
}
