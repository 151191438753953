import config from '../../config'
import countries from '../data/countries.json'
import countryCurrencies from '../data/countries_currencies.json'
import { CountryDataCollection } from './geotools'

const fetchJson = <T>(url: string) =>
	fetch(url).then((res) => res.json() as Promise<T>)

export interface RatesData {
	base: string
	rates: { [country: string]: number }
	timestampe: number
}
export const getCurrencyRates = () => fetchJson<RatesData>(config.data.rates)

export interface CurrenciesData {
	[country: string]: string
}
export const getCurrencies = () =>
	fetchJson<CurrenciesData>(config.data.currencies)

export const getCountries = () =>
	fetchJson<CountryDataCollection>(countries as unknown as string)

export interface CountryCurrencies {
	[country: string]: string
}
export const getCountryCurrencies = () =>
	fetchJson<CountryCurrencies>(countryCurrencies as unknown as string)
